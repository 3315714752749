import React, { useEffect, useMemo, useRef, useState, FC } from 'react'
import {
    StyleProp,
    StyleSheet,
    ViewStyle,
    View,
    ScrollView,
    TextStyle,
    Text,
    Pressable,
    Animated,
    Dimensions,
    PressableProps,
} from 'react-native'
import { usePlatfomHook } from '../../../utils/getPlatformType'
import OutsideClickHandler from '../../../molecules/OutsideClickHandler'
import Button from '../../Button/Button'
import { Portal } from '@gorhom/portal'

export interface SideNavigationProps extends Omit<PressableProps, 'onPress'> {
    testID?: string
    header?: string
    headerTextStyle?: StyleProp<TextStyle>
    containerStyle?: ViewStyle
    contentContainerStyle?: ViewStyle
    showResponsiveButton?: boolean
    responsiveButton?: React.ReactNode
    items: Array<SideNavigationItem>
    onPress: (menuItem: any, subMenuItem: any) => any
    /* Closes hidden side navigation when onPress when on mobile. Default = true */
    closeHidenSideNavOnPress?: boolean
    /* showResponsiveDrawerOverride used specifically for TopNav where we only want to show responsive sideNav */
    showResponsiveDrawerOverride?: boolean
    /* showResponsiveDrawer used specifically for TopNav to control showing/hiding responsive SideNav */
    showResponsiveDrawer?: boolean
}

export interface SideNavigationItem {
    id: string
    text: string
    isSelected?: boolean
    textStyle?: TextStyle
    buttonStyle?: ViewStyle
    isSelectedTextStyle?: TextStyle
    isSelectedButtonStyle?: ViewStyle
    subMenuExpanded?: boolean
    hideSubMenuOnSelect?: boolean
    items?: Array<SideNavigationItem>
    containerStyle?: ViewStyle
}

export const SideNavigation: FC<SideNavigationProps> = ({
    testID,
    containerStyle,
    contentContainerStyle,
    onPress,
    items,
    header,
    headerTextStyle,
    showResponsiveButton = true,
    responsiveButton,
    showResponsiveDrawerOverride = false,
    showResponsiveDrawer,
    closeHidenSideNavOnPress = true,
}) => {
    const [sideNavContainerWidth, setSideNavContainerWidth] = useState(0)
    const { screenType, isDesktop, isMobile, isTablet } = usePlatfomHook()
    const onLoad = useRef(false)
    const slideDrawerAnim = useRef(
        new Animated.Value(Dimensions.get('screen').width * -1),
    ).current

    const sideNavTop = parseInt(containerStyle?.top?.toString() || '0')

    useEffect(() => {
        if (onLoad.current) {
            adjustDrawer(false)
        }
    }, [showResponsiveDrawer])

    const handleSideNavLayout = (event) => {
        if (!onLoad.current) {
            onLoad.current = true
            Animated.timing(slideDrawerAnim, {
                toValue: event.nativeEvent.layout?.width * -1,
                duration: 100,
                useNativeDriver: false,
            }).start()
        }
        setSideNavContainerWidth(event.nativeEvent.layout?.width)
    }

    const adjustDrawer = (outSideClickHandler: boolean) => {
        const slideDrawerAnimValue = parseInt(JSON.stringify(slideDrawerAnim))
        if (
            outSideClickHandler &&
            slideDrawerAnimValue == sideNavContainerWidth * -1
        ) {
            return
        }

        Animated.timing(slideDrawerAnim, {
            toValue: slideDrawerAnimValue == 0 ? sideNavContainerWidth * -1 : 0,
            duration: 150,
            useNativeDriver: false,
        }).start()
    }

    const handleOnPress = (
        item: SideNavigationItem,
        subItem?: SideNavigationItem,
    ) => {
        closeHidenSideNavOnPress && adjustDrawer(false)
        onPress?.(item, subItem)
    }

    const handleListItem = (item: SideNavigationItem) => {
        return (
            <View
                key={item.id}
                style={[item.containerStyle, styles.navigationContainerItem]}
            >
                <Pressable
                    testID={`${testID}-${item.id}-side-nav-nav-item`}
                    style={
                        item.isSelected
                            ? item.isSelectedButtonStyle
                            : item.buttonStyle
                    }
                    onPress={() => handleOnPress(item, undefined)}
                >
                    <Text
                        style={[
                            styles.navigationTextItem,
                            item.isSelected
                                ? {
                                      ...styles.isSelectedTextStyle,
                                      ...item.isSelectedTextStyle,
                                  }
                                : item.textStyle,
                        ]}
                    >
                        {item.text}
                    </Text>
                </Pressable>
                {item?.items?.length && item.subMenuExpanded && (
                    <>
                        {item.items.map((subItem) => {
                            return (
                                <Pressable
                                    key={subItem.id}
                                    testID={`${testID}-${subItem.id}-side-nav-nav-sub-item`}
                                    style={
                                        subItem.isSelected
                                            ? subItem.isSelectedButtonStyle
                                            : subItem.buttonStyle
                                    }
                                    onPress={() => handleOnPress(item, subItem)}
                                >
                                    <Text
                                        style={[
                                            styles.subMenuNavigationTextItem,
                                            item.isSelected
                                                ? {
                                                      ...styles.isSelectedSubMenuTextStyle,
                                                      ...item.isSelectedTextStyle,
                                                  }
                                                : item.textStyle,
                                        ]}
                                    >
                                        {subItem.text}
                                    </Text>
                                </Pressable>
                            )
                        })}
                    </>
                )}
            </View>
        )
    }

    const getSideNavContent = () => {
        if (isDesktop && showResponsiveDrawerOverride) {
            return null
        }
        const sideNavStyles = !showResponsiveDrawerOverride
            ? [styles.container, containerStyle]
            : styles.container
        return (
            <ScrollView
                style={sideNavStyles}
                contentContainerStyle={[
                    styles.contentContainerStyle,
                    contentContainerStyle,
                ]}
            >
                {!!header && (
                    <Text style={[styles.header, headerTextStyle]}>
                        {header}
                    </Text>
                )}
                <>{items?.map(handleListItem)}</>
            </ScrollView>
        )
    }

    const getHiddenSideNavContent = () => {
        if (isDesktop) {
            return null
        }
        const renderResponsiveButton = () => {
            if (!showResponsiveButton) {
                return
            }

            if (responsiveButton) {
                return responsiveButton
            }

            return (
                <Button
                    testID={`${testID}-responsive-button`}
                    text="Open"
                    onPress={() => adjustDrawer(false)}
                />
            )
        }
        return (
            <>
                {renderResponsiveButton()}
                <OutsideClickHandler onPress={() => adjustDrawer(true)}>
                    <Portal>
                        <Animated.View
                            testID={`${testID}-hidden-side-nav`}
                            style={[
                                styles.hiddenSideNav,
                                containerStyle,
                                {
                                    top: sideNavTop,
                                    left: slideDrawerAnim,
                                    height:
                                        Dimensions.get('screen').height -
                                        sideNavTop,
                                    minWidth: 200,
                                },
                            ]}
                            onLayout={handleSideNavLayout}
                        >
                            <>{getSideNavContent()}</>
                        </Animated.View>
                    </Portal>
                </OutsideClickHandler>
                <View
                    style={[
                        styles.shadow,
                        {
                            display: showResponsiveDrawer ? 'flex' : 'none',
                            left: 0,
                            right: 0,
                            top: containerStyle?.top || 0,
                            height:
                                Dimensions.get('screen').height -
                                parseInt(
                                    containerStyle?.top?.toString() || '0',
                                ),
                        },
                    ]}
                />
            </>
        )
    }

    const sideNav = useMemo(() => {
        if (isMobile || isTablet) {
            return getHiddenSideNavContent()
        } else if (!showResponsiveDrawerOverride) {
            return getSideNavContent()
        }
        return null
    }, [screenType, containerStyle, items, sideNavContainerWidth])

    return <>{sideNav}</>
}

export default SideNavigation

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 16,
        borderRightColor: 'gray',
        borderRightWidth: 1,
        width: '100%',
    },
    contentContainerStyle: {
        height: '100%',
    },
    header: {
        fontSize: 20,
        fontWeight: '700',
        lineHeight: 28,
        paddingVertical: 8,
        paddingHorizontal: 12,
        alignItems: 'center',
    },
    navigationContainerItem: {
        paddingVertical: 4,
    },
    navigationTextItem: {
        fontSize: 16,
        lineHeight: 24,
        paddingVertical: 8,
        paddingHorizontal: 12,
        alignItems: 'center',
    },
    subMenuNavigationTextItem: {
        paddingVertical: 6,
        paddingHorizontal: 16,
        fontSize: 14,
        lineHeight: 22,
        fontWeight: '400',
        borderLeftColor: 'gray',
        borderLeftWidth: 1,
    },
    isSelectedSubMenuTextStyle: {
        borderLeftColor: '#6c00ea',
        borderLeftWidth: 2,
    },
    isSelectedTextStyle: {
        backgroundColor: '#f4edff',
        borderRadius: 5,
    },
    hiddenSideNav: {
        position: 'absolute',
        zIndex: 10,
        bottom: 0,
        minWidth: 200,
    },
    shadow: {
        position: 'absolute',
        zIndex: 9,
        bottom: 0,
        backgroundColor: 'black',
        opacity: 0.5,
    },
})
