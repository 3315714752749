import { Dimensions, StyleSheet } from 'react-native'
import COLORS from '../../../utils/colors'

export default StyleSheet.create({
    subMenuContainer: {
        borderBottomRightRadius: 30,
        backgroundColor: COLORS.COLOR_WHITE,
        width: 350,
        paddingLeft: 20,
        paddingVertical: 20,
        paddingHorizontal: 20,
    },
    sideMenuIcon: {
        // color: COLORS.COLOR_BLACK,
        width: 20,
        height: 20,
        marginRight: 10,
    },
    sideMenuTouchIcon: {
        color: '#6200EA',
        width: 20,
        height: 20,
        marginRight: 10,
        tintColor: '#6200EA',
    },
    sideMenuButtonStyle: {
        flexDirection: 'row',
        marginTop: 15,
        backgroundColor: COLORS.COLOR_WHITE,
        paddingVertical: 15,
        paddingHorizontal: 15,
    },
    sideMenuButtonTouchStyle: {
        flexDirection: 'row',
        marginTop: 15,
        backgroundColor: '#F6F0FF',
        paddingVertical: 15,
        paddingHorizontal: 15,
    },
    sideMenuNameStyle: {
        fontSize: 18,
    },
    sideMenuDescriptionStyle: {
        fontSize: 12,
        marginTop: 10,
    },
    sideMenuDescriptionTouchStyle: {
        fontSize: 12,
        marginTop: 10,
        color: '#334155',
    },
    sideMenuTouchNameStyle: {
        fontSize: 18,
        color: '#6200EA',
    },
    mainView: {
        backgroundColor: '#F1F5F9',
        height: Dimensions.get('window').height,
        flexDirection: 'row',
    },

    subMenuTitle: {
        color: '#64748B',
        fontSize: 20,
        fontWeight: 'bold',
    },
    subMenuTextContainer: { display: 'flex', width: "100%" },
    mainMenuContainer: {
        backgroundColor: COLORS.COLOR_WHITE,
        width: 100,
        borderRightWidth: 1,
        borderRightColor: COLORS.COLOR_LIGHTGRAY,
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingVertical: 20,
    },
    sidebuttoniconNameStyle: {
        paddingVertical: 15,
        width: '100%',
        alignItems: 'center',
    },
    sideTabIconStyle: {
        //  tintColor:  COLORS.COLOR_GRAY,
        width: 16,
        height: 16,
        alignSelf: 'center',
    },
    sideTabIconPressStyle: {
        width: 16,
        height: 16,
        alignSelf: 'center',
        tintColor: COLORS.COLOR_MEDIUMBLUE,
    },
    sideTabButtonTextStyle: {
        color: COLORS.COLOR_GRAY,
        fontSize: 10,
        marginTop: 5,
        textAlign: 'center',
    },
    sideTabButtonTouchTextStyle: {
        color: COLORS.COLOR_MEDIUMBLUE,
        fontSize: 10,
        marginTop: 5,
        textAlign: 'center',
    },
    avtarStyle: {
        borderColor: COLORS.COLOR_LIGHTPURPLE,
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
    },
    imageStyle: {
        width: 30,
        height: 30,
        alignSelf: 'center',
    },
    avtarView: {
        alignItems: 'center',
    },
    backViewStyle: {
        backgroundColor: COLORS.COLOR_MEDIUMBLUE,
        width: 5,
    },
    tabView: {
        flexDirection: 'row',
        alignContent: 'center',
        width: '100%',
    },
})
